body {
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "aesthet-nova", serif;
  font-weight: 500;
  color: #1f3b7b;
  margin-top: 0;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
p {
  font-size: 17px;
  line-height: 1.4;
  color: #5b709e;
  margin-top: 0;
}
input {
  box-sizing: border-box;
}

@media only screen and (max-width: 550px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 15px;
  }
}
